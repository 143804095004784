// react
import { createContext, useReducer } from "react";
import { Outlet } from "react-router-dom";

// Initial State
const initialState = {
  primaryCategory: {},
  additionalCategory: [],
  description: "",
  website: "",
  openingHours: [
    {
      id: "1",
      closed: false,
      day: "Monday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "2",
      closed: false,
      day: "Tuesday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "3",
      closed: false,
      day: "Wednesday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "4",
      closed: false,
      day: "Thursday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "5",
      closed: false,
      day: "Friday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "6",
      closed: false,
      day: "Saturday",
      opensAt: "",
      closeAt: "",
    },
    {
      id: "7",
      closed: false,
      day: "Sunday",
      opensAt: "",
      closeAt: "",
    },
  ],
  holidayOpeningHours: [
    {
      id: Math.floor(Math.random() * 100 + 1),
      closed: false,
      date: null,
      opensAt: "",
      closeAt: "",
    },
  ],
  attributes: [],
};

// Action handlers
const handlers = {
  UPDATE_PRIMARY_CATEGORY: (state, action) => {
    const { primaryCategory } = action.payload;
    return {
      ...state,
      primaryCategory: primaryCategory,
    };
  },
  UPDATE_ADDITIONAL_CATEGORY: (state, action) => {
    const { additionalCategory } = action.payload;
    return {
      ...state,
      additionalCategory: additionalCategory,
    };
  },
  UPDATE_DESCRIPTION: (state, action) => {
    const { description } = action.payload;
    return {
      ...state,
      description: description,
    };
  },
  UPDATE_WEBSITE: (state, action) => {
    const { website } = action.payload;
    return {
      ...state,
      website: website,
    };
  },
  UPDATE_OPENING_HOURS: (state, action) => {
    const { openingHours } = action.payload;
    return {
      ...state,
      openingHours: openingHours,
    };
  },
  UPDATE_HOLIDAY_OPENING_HOURS: (state, action) => {
    const { holidayOpeningHours } = action.payload;
    return {
      ...state,
      holidayOpeningHours: holidayOpeningHours,
    };
  },
  UPDATE_ATTRIBUTES: (state, action) => {
    const { attributes } = action.payload;
    return {
      ...state,
      attributes: attributes,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const UpdateProfileContext = createContext();

// Provider Component
export const UpdateProfileProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updatePrimaryCategory = (primaryCategory) => {
    dispatch({
      type: "UPDATE_PRIMARY_CATEGORY",
      payload: { primaryCategory },
    });
  };
  const updateAdditionalCategory = (additionalCategory) => {
    dispatch({
      type: "UPDATE_ADDITIONAL_CATEGORY",
      payload: { additionalCategory },
    });
  };
  const updateDescription = (description) => {
    dispatch({
      type: "UPDATE_DESCRIPTION",
      payload: { description },
    });
  };
  const updateWebsite = (website) => {
    dispatch({
      type: "UPDATE_WEBSITE",
      payload: { website },
    });
  };
  const updateOpeningHours = (openingHours) => {
    dispatch({ type: "UPDATE_OPENING_HOURS", payload: { openingHours } });
  };
  const updateHolidayOpeningHours = (holidayOpeningHours) => {
    dispatch({
      type: "UPDATE_HOLIDAY_OPENING_HOURS",
      payload: { holidayOpeningHours },
    });
  };
  const updateAttributes = (attributes) => {
    dispatch({
      type: "UPDATE_ATTRIBUTES",
      payload: { attributes },
    });
  };
  return (
    <UpdateProfileContext.Provider
      value={{
        state: state,
        updatePrimaryCategory,
        updateAdditionalCategory,
        updateDescription,
        updateWebsite,
        updateOpeningHours,
        updateHolidayOpeningHours,
        updateAttributes,
      }}
    >
      <Outlet />
    </UpdateProfileContext.Provider>
  );
};
