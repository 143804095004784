// react
import { createContext, useCallback, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";
import getAccessibleCustomers from "src/services/googleAds/getAccessibleCustomers";
import getCampaigns from "src/services/googleAds/getCampaigns";
// Initial State
const initialState = {
  login_url: null,
  accounts: [],
  accessibleCustomers: null,
  campaigns: null,
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { login_url, googleAdsAccounts } = action.payload;
    return {
      ...state,
      login_url,
      accounts: googleAdsAccounts,
      loading: false,
    };
  },
  STORE_ACCESSIBLE_CUSTOMERS: (state, action) => {
    const { res } = action.payload;
    return {
      ...state,
      accessibleCustomers: res,
    };
  },
  STORE_CAMPAIGN_DATA: (state, action) => {
    const { res } = action.payload;
    return {
      ...state,
      campaigns: res,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GoogleAdsContext = createContext();

// Provider Component
export const GoogleAdsProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: `https://demoads.emovur.xyz/api/v1/ads/auth/login_url`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { login_url, googleAdsAccounts } = await response.data;
        // cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            googleAdsAccounts,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url: null,
            accounts: [],
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          login_url: null,
          accounts: [],
        },
      });
    }
  };

  const getAccessibleCustomersHandler = useCallback(async (gId) => {
    try {
      const res = await getAccessibleCustomers(gId);
      dispatch({
        type: "STORE_ACCESSIBLE_CUSTOMERS",
        payload: { res },
      });
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  const getCampaignsHandler = useCallback(
    async (gId, id, dateRange, campaignId, metricsArray) => {
      try {
        const res = await getCampaigns(
          gId,
          id,
          dateRange,
          campaignId,
          metricsArray
        );
        dispatch({
          type: "STORE_CAMPAIGN_DATA",
          payload: { res },
        });
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  // useEffect(() => {
  //   initialize();
  // }, []);

  return (
    <GoogleAdsContext.Provider
      value={{
        accounts: state.accounts,
        loginUrl: state.login_url,
        loading: state.loading,
        accessibleCustomers: state.accessibleCustomers,
        campaigns: state.campaigns,
        getAccessibleCustomersHandler,
        getCampaignsHandler,
        initialize,
      }}
    >
      <Outlet />
      {/* {children} */}
    </GoogleAdsContext.Provider>
  );
};
