import axios from "src/utils/axios";
import { GOOGLE_ADS_URL } from "src/config";

export default async function getCampaigns(
  orgId,
  customer_id,
  manager_id,
  dateRange
) {
  try {
    const response = await axios({
      method: "get",
      url: `${GOOGLE_ADS_URL}/api/google-ads/list-campaigns`,
      params: {
        org_id: orgId,
        customer_id,
        manager_id,
        dateRange,
      },
    });
    const res = response.data;
    return res;
  } catch (error) {
    throw error;
  }
}
