import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

//  Create context
export const LinkedInContext = createContext();

//  Initial state
const initialState = {
  login_url: null,
  accounts: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { login_url, accounts } = action.payload;
    return { ...state, login_url, accounts };
  },
};

//  Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

//  Create provider
export const LinkedInContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios.get(
          `lms_user/api/social/linkedin/get_accounts`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const { login_url, accounts } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            accounts,
          },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const setupLinkedIn = async (setupData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "post",
          url: `lms_user/api/social/linkedin/setup_account`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: setupData,
        });
        const { login_url, accounts } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            accounts,
          },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const removeLinkedInAcc = async (id) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "delete",
          url: `lms_user/api/social/linkedin/setup_account/${id}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { login_url, accounts } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            accounts,
          },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => initialize(), []);

  return (
    <LinkedInContext.Provider
      value={{
        login_url: state.login_url,
        accounts: state.accounts,
        setupLinkedIn,
        removeLinkedInAcc,
      }}
    >
      <Outlet />
    </LinkedInContext.Provider>
  );
};
