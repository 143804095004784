import { createContext, useCallback, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import getFlowsDetails from "src/services/whatsApp/flows/getFlowsDetails";

// Initial State
const initialState = {
  flows: [],
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { flows } = action.payload;
    return {
      ...state,
      flows: flows,
      loading: false,
    };
  },
  UPDATE_FLOWSDETAILS: (state, action) => {
    const { flows } = action.payload;
    return {
      ...state,
      flows: flows,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const WhatsAppFlowTableContext = createContext();

export const WhatsAppFlowTableProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const response = await getFlowsDetails();
      const { flows } = await response;
      dispatch({
        type: "INITIALIZE",
        payload: {
          flows,
        },
      });
    } catch (error) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          flows: [],
          loading: false,
        },
      });
      console.log(error);
      throw new Error(error);
    }
  };

  const updateFlowsdetails = useCallback((flows) => {
    dispatch({ type: "UPDATE_FLOWSDETAILS", payload: { flows } });
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <WhatsAppFlowTableContext.Provider
      value={{
        flows: state.flows,
        loading: state.loading,
        initialize,
        updateFlowsdetails,
      }}
    >
      <Outlet />
    </WhatsAppFlowTableContext.Provider>
  );
};
