// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "8px, 10px",
          fontWeight: theme.typography.fontWeightMedium,
          // borderTopLeftRadius: theme.shape.borderRadius,
          // borderTopRightRadius: theme.shape.borderRadius,
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            backgroundColor: "#eef3fb",
            // color: "#2065D1",
          },
          "&:not(:last-of-type)": {
            marginRight: theme.spacing(5),
          },
          "@media (min-width: 600px)": {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: "row",
          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapper: {
          flexDirection: "row",
          whiteSpace: "nowrap",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        vertical: {
          "& .MuiTab-root": {
            marginRight: theme.spacing(0),
            alignItems: "start",
            paddingLeft: "10px",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: 0,
          },
        },
      },
    },
  };
}
