import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getPostInsights(id) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios.get(`lms_user/api/social/post/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const { report } = await response.data;
      return report;
    }
  } catch (error) {
    throw new Error(error);
  }
}
