// react
import { createContext, useCallback, useReducer } from "react";
import { Outlet } from "react-router-dom";

// Initial State
const initialState = {
  form1: {
    category: "MARKETING",
    subCategory: "custom",
    name: "",
    languages: { value: "en", name: "English" },
  },
  form2: {
    headerType: "none",
    headerText: "",
    mediaType: "",
    bodyText: "",
    catalogueFormat: "",
    footerText: "",
    buttonType: "",
    callToActionArr: [],
    quickReplyArr: [],
    bodyVariableCount: [],
    dynamicUrlStatus: false,
    buttonsArray: [
      { id: "callToAction", callToAction: [] },
      { id: "quickReply", quickReply: [] },
    ],
    codeDeliverySetupType: "copy_code",
    securityRecommendation: true,
    codeExpiryChecked: false,
    codeExpirationMinutes: 10,
    authenticationButton: "Copy code",
  },
  sample: {
    headerText: "",
    headerMediaFile: "",
    bodyText: "",
    dynamicUrl: "",
    bodyVars: {},
    headerVars: {},
    mediaApiResponse: "",
  },
};

// Action handlers
const handlers = {
  // FORM 1 HANDLERS
  UPDATE_CATEGORY: (state, action) => {
    const { category } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, category },
    };
  },
  UPDATE_SUB_CATEGORY: (state, action) => {
    const { subCategory } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, subCategory },
    };
  },
  UPDATE_NAME: (state, action) => {
    const { name } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, name },
    };
  },
  UPDATE_LANGUAGES: (state, action) => {
    const { languages } = action.payload;
    return {
      ...state,
      form1: { ...state.form1, languages },
    };
  },

  // FORM 2 HANDLERS
  UPDATE_HEADER_TYPE: (state, action) => {
    const { headerType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, headerType },
    };
  },

  UPDATE_HEADER_TEXT: (state, action) => {
    const { headerText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, headerText },
      sample: { ...state.sample, headerText },
    };
  },

  UPDATE_MEDIA_TYPE: (state, action) => {
    const { mediaType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, mediaType },
    };
  },
  UPDATE_BODY_TEXT: (state, action) => {
    const { bodyText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, bodyText },
      sample: { ...state.sample, bodyText },
    };
  },
  UPDATE_CATALOGUE_FORMAT: (state, action) => {
    const { catalogueFormat } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, catalogueFormat },
    };
  },
  UPDATE_FOOTER_TEXT: (state, action) => {
    const { footerText } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, footerText },
    };
  },
  UPDATE_BUTTON_TYPE: (state, action) => {
    const { buttonType } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, buttonType },
    };
  },
  UPDATE_QUICKREPLY_ARR: (state, action) => {
    const { quickReplyArr } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, quickReplyArr },
    };
  },
  UPDATE_CALLTOACTION_ARR: (state, action) => {
    const { callToActionArr } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, callToActionArr },
    };
  },
  UPDATE_BUTTONS_ARR: (state, action) => {
    const { buttonsArray } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, buttonsArray },
    };
  },
  UPDATE_BODY_VARIABLE_COUNT: (state, action) => {
    const { bodyVariableCount } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, bodyVariableCount },
    };
  },
  UPDATE_DYNAMIC_URL_STATUS: (state, action) => {
    const { dynamicUrlStatus } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, dynamicUrlStatus },
    };
  },

  // SAMPLE HANDLERS
  UPDATE_SAMPLE_BODYTEXT: (state, action) => {
    const { bodyText } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, bodyText },
    };
  },
  UPDATE_SAMPLE_BODYVARS: (state, action) => {
    const { bodyVars } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, bodyVars },
    };
  },
  UPDATE_SAMPLE_HEADERTEXT: (state, action) => {
    const { headerText } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerText },
    };
  },
  UPDATE_SAMPLE_HEADERVARS: (state, action) => {
    const { headerVars } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerVars },
    };
  },
  UPDATE_SAMPLE_HEADER_MEDIAFILE: (state, action) => {
    const { headerMediaFile } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, headerMediaFile },
    };
  },
  UPDATE_SAMPLE_DYNAMIC_URL: (state, action) => {
    const { dynamicUrl } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, dynamicUrl },
    };
  },
  UPDATE_MEDIA_API_RESPONSE: (state, action) => {
    const { mediaApiResponse } = action.payload;
    return {
      ...state,
      sample: { ...state.sample, mediaApiResponse },
    };
  },
  UPDATE_AUTHENTICATION_BUTTON: (state, action) => {
    const { authenticationButton } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, authenticationButton },
    };
  },
  UPDATE_SECURITY_RECOMMENDATION: (state, action) => {
    const { securityRecommendation } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, securityRecommendation },
    };
  },
  UPDATE_CODE_EXPIRY_CHECKED: (state, action) => {
    const { codeExpiryChecked } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, codeExpiryChecked },
    };
  },
  UPDATE_CODE_EXPIRATION_MINUTES: (state, action) => {
    const { codeExpirationMinutes } = action.payload;
    return {
      ...state,
      form2: { ...state.form2, codeExpirationMinutes },
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const CreateTemplateContext = createContext();

// Provider Component
export const CreateTemplateProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // FORM 1 FUNCTIONS
  const updateCategory = useCallback((category) => {
    dispatch({
      type: "UPDATE_CATEGORY",
      payload: { category },
    });
  }, []);

  const updateSubCategory = useCallback((subCategory) => {
    dispatch({
      type: "UPDATE_SUB_CATEGORY",
      payload: { subCategory },
    });
  }, []);

  const updateName = (name) => {
    dispatch({ type: "UPDATE_NAME", payload: { name } });
  };
  const updateLanguages = (languages) => {
    dispatch({ type: "UPDATE_LANGUAGES", payload: { languages } });
  };

  // FORM 2 FUNCTIONS
  const updateHeaderType = useCallback((headerType) => {
    dispatch({ type: "UPDATE_HEADER_TYPE", payload: { headerType } });
  }, []);
  const updateHeaderText = (headerText) => {
    dispatch({ type: "UPDATE_HEADER_TEXT", payload: { headerText } });
  };
  const updateMediaType = (mediaType) => {
    dispatch({ type: "UPDATE_MEDIA_TYPE", payload: { mediaType } });
  };
  const updateBodyText = useCallback((bodyText) => {
    dispatch({ type: "UPDATE_BODY_TEXT", payload: { bodyText } });
  }, []);

  const updateCatalogueFormat = useCallback((catalogueFormat) => {
    dispatch({ type: "UPDATE_CATALOGUE_FORMAT", payload: { catalogueFormat } });
  }, []);

  const updateFooterText = useCallback((footerText) => {
    dispatch({ type: "UPDATE_FOOTER_TEXT", payload: { footerText } });
  }, []);
  const updateButtonType = (buttonType) => {
    dispatch({ type: "UPDATE_BUTTON_TYPE", payload: { buttonType } });
  };
  const updateQuickReplyArr = useCallback((quickReplyArr) => {
    dispatch({
      type: "UPDATE_QUICKREPLY_ARR",
      payload: { quickReplyArr },
    });
  }, []);
  const updateCallToActionArr = (callToActionArr) => {
    dispatch({
      type: "UPDATE_CALLTOACTION_ARR",
      payload: { callToActionArr },
    });
  };
  const updateButtonsArr = (buttonsArray) => {
    dispatch({
      type: "UPDATE_BUTTONS_ARR",
      payload: { buttonsArray },
    });
  };
  const updateBodyVariableCount = (bodyVariableCount) => {
    dispatch({
      type: "UPDATE_BODY_VARIABLE_COUNT",
      payload: { bodyVariableCount },
    });
  };
  const updateDynamicUrlStatus = (dynamicUrlStatus) => {
    dispatch({
      type: "UPDATE_DYNAMIC_URL_STATUS",
      payload: { dynamicUrlStatus },
    });
  };

  // SAMPLE FUNCTIONS
  const updateSampleBodyText = useCallback((bodyText) => {
    dispatch({
      type: "UPDATE_SAMPLE_BODYTEXT",
      payload: { bodyText },
    });
  }, []);
  const updateSampleBodyVars = (bodyVars) => {
    dispatch({
      type: "UPDATE_SAMPLE_BODYVARS",
      payload: { bodyVars },
    });
  };
  const updateSampleHeaderText = (headerText) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADERTEXT",
      payload: { headerText },
    });
  };
  const updateSampleHeaderVars = (headerVars) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADERVARS",
      payload: { headerVars },
    });
  };
  const updateSampleHeaderMediaFile = (headerMediaFile) => {
    dispatch({
      type: "UPDATE_SAMPLE_HEADER_MEDIAFILE",
      payload: { headerMediaFile },
    });
  };
  const updateSampleDynamicUrl = (dynamicUrl) => {
    dispatch({
      type: "UPDATE_SAMPLE_DYNAMIC_URL",
      payload: { dynamicUrl },
    });
  };
  const updateMediaApiResponse = (mediaApiResponse) => {
    dispatch({
      type: "UPDATE_MEDIA_API_RESPONSE",
      payload: { mediaApiResponse },
    });
  };
  const updateAuthenticationButton = useCallback((authenticationButton) => {
    dispatch({
      type: "UPDATE_AUTHENTICATION_BUTTON",
      payload: { authenticationButton },
    });
  }, []);
  const updateSecurityRecommendation = useCallback((securityRecommendation) => {
    dispatch({
      type: "UPDATE_SECURITY_RECOMMENDATION",
      payload: { securityRecommendation },
    });
  }, []);
  const updateCodeExpiryChecked = useCallback((codeExpiryChecked) => {
    dispatch({
      type: "UPDATE_CODE_EXPIRY_CHECKED",
      payload: { codeExpiryChecked },
    });
  }, []);
  const updateCodeExpirationMinutes = useCallback((codeExpirationMinutes) => {
    dispatch({
      type: "UPDATE_CODE_EXPIRATION_MINUTES",
      payload: { codeExpirationMinutes },
    });
  }, []);

  return (
    <CreateTemplateContext.Provider
      value={{
        form1: state.form1,
        form2: state.form2,
        sample: state.sample,
        updateCategory,
        updateSubCategory,
        updateName,
        updateLanguages,
        updateHeaderType,
        updateHeaderText,
        updateMediaType,
        updateBodyText,
        updateCatalogueFormat,
        updateButtonType,
        updateFooterText,
        updateQuickReplyArr,
        updateCallToActionArr,
        updateBodyVariableCount,
        updateSampleBodyText,
        updateSampleBodyVars,
        updateSampleHeaderText,
        updateSampleHeaderVars,
        updateSampleHeaderMediaFile,
        updateSampleDynamicUrl,
        updateDynamicUrlStatus,
        updateMediaApiResponse,
        updateButtonsArr,
        updateAuthenticationButton,
        updateSecurityRecommendation,
        updateCodeExpirationMinutes,
        updateCodeExpiryChecked,
      }}
    >
      <Outlet />
    </CreateTemplateContext.Provider>
  );
};
